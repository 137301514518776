$(document).on("turbolinks:before-render", function() {
  // $("#loader-wrapper").attr('display', 'block');
});

document.addEventListener("turbolinks:load", function () {
  /*===================================*
  01. LOADING JS
  /*===================================*/
  // $("a[data-swal-waiting]").on('click', function(e) {
  //   e.preventDefault();
  //   Swal.showLoading();
  //   Turbolinks.visit($(this).attr('href'))
  // })
})
