// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("bootstrap")

import "@fortawesome/fontawesome-free/css/all"
import "../stylesheets/application"

var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('./sb-admin-2.js');
require('./_normal.js');
// home page - ninestars
require('./ninestars/_home.js');


import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';
Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);
window.Chart = Chart;

// require('./sweet-alert-confirm');
import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

window.Swal = Swal;

import Sortable from 'sortablejs';

var interval1,
    interval2,
    interval3,
    interval4,
    interval5,
    interval6,
    is_requesting = false;
$(document).on("turbolinks:before-render", function() {
  clearInterval(interval1);
  clearInterval(interval2);
  clearInterval(interval3);
  clearInterval(interval4);
  clearInterval(interval5);
  clearInterval(interval6);
  is_requesting = false;
});

document.addEventListener("turbolinks:load", function() {
  /*===================================*
  01. Analytics Index
  /*===================================*/
  let charts    = [];

  if ($(".js-analytics-index").length > 0) {
    (function(w,d,s,g,js,fs){
      g=w.gapi||(w.gapi={});g.analytics={q:[],ready:function(f){this.q.push(f);}};
      js=d.createElement(s);fs=d.getElementsByTagName(s)[0];
      js.src='https://apis.google.com/js/platform.js';
      fs.parentNode.insertBefore(js,fs);js.onload=function(){g.load('analytics');};
    }(window,document,'script'));
    $(".js-chart-count").each(function (index, el) {
      let ctx        = $(el),
          data_chart = Array(30),
          columns    = 30,
          number_cfg = { count: columns,
                         min: 0,
                         max: 1 },
          labels     = ['29 phút trước', '28 phút trước', '27 phút trước', '26 phút trước', '25 phút trước',
                        '24 phút trước', '23 phút trước', '22 phút trước', '21 phút trước', '20 phút trước',
                        '19 phút trước', '18 phút trước', '17 phút trước', '16 phút trước', '15 phút trước',
                        '14 phút trước', '13 phút trước', '12 phút trước', '11 phút trước', '10 phút trước',
                        '9 phút trước', '8 phút trước', '7 phút trước', '6 phút trước', '5 phút trước',
                        '4 phút trước', '3 phút trước', '2 phút trước', '1 phút trước', '0 phút trước']
      let data = {
        labels: labels,
        datasets: [
          {
            // label: 'Dataset 1',
            data: data_chart,
            borderColor: '#4e73df',
            backgroundColor: '#4e73df',
          }
        ]
      };
      let config = {
        type: 'bar',
        data: data,
        options: {
          scales: {
            x: {
              ticks: {
                display: false
              },
              grid: {
                display: false
              }
            },
            y: {
              ticks: {
                display: false
              },
              grid: {
                display: false
              }
            }
          },
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: false
            }
          }
        },
      };
      if (ctx.length > 0) {
        charts.push(new Chart(ctx, config));
      }
    })

    gapi.analytics.ready(function() {

      /**
       * Authorize the user with an access token obtained server side.
       */
      let $jsData   = $('.js-data'),
          auth      = $jsData.data('token'),
          viewids    = $jsData.data('viewid');
      gapi.analytics.auth.authorize({
        'serverAuth': {
          'access_token': auth
        }
      });
      var batch1 = gapi.client.newBatch();
      var batch2 = gapi.client.newBatch();
      var batch3 = gapi.client.newBatch();
      var batch4 = gapi.client.newBatch();
      var batch5 = gapi.client.newBatch();
      var batch6 = gapi.client.newBatch();

      viewids.forEach((viewid, index) => {
        let activeusers = new gapi.client.analytics.data.realtime.get({
          'ids': viewid,
          'metrics': 'rt:activeUsers',
          'dimensions': 'rt:pagePath, rt:country, rt:medium',
          'sort': '-rt:activeUsers',
          'max-results': 1000
        });
        let pageviews = new gapi.client.analytics.data.realtime.get({
          'ids': viewid,
          'metrics': 'rt:pageviews',
          'dimensions': 'rt:minutesAgo'
        });
        switch (true) {
          case (index < 5):
            batch1.add(activeusers, {id: `${index}-1`, type: 'activeusers' });
            batch1.add(pageviews, {id: `${index}-2`, type: 'pageviews' });
            break;
          case (index < 10):
            batch2.add(activeusers, {id: `${index-5}-1`, type: 'activeusers' });
            batch2.add(pageviews, {id: `${index-5}-2`, type: 'pageviews' });
            break;
          case (index < 15):
            batch3.add(activeusers, {id: `${index-10}-1`, type: 'activeusers' });
            batch3.add(pageviews, {id: `${index-10}-2`, type: 'pageviews' });
            break;
          case (index < 20):
            batch4.add(activeusers, {id: `${index-15}-1`, type: 'activeusers' });
            batch4.add(pageviews, {id: `${index-15}-2`, type: 'pageviews' });
            break;
          case (index < 25):
            batch5.add(activeusers, {id: `${index-20}-1`, type: 'activeusers' });
            batch5.add(pageviews, {id: `${index-20}-2`, type: 'pageviews' });
            break;
          case (index < 30):
            batch6.add(activeusers, {id: `${index-25}-1`, type: 'activeusers' });
            batch6.add(pageviews, {id: `${index-25}-2`, type: 'pageviews' });
            break;
        }
        
      })

      batch1.execute(function (responseMap) {
        console.log("request lan dau")
        for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
          let dataActiveusers = responseMap[`${i}-1`],
              dataPageviews   = responseMap[`${i}-2`];
          updateAnalytics(dataActiveusers, dataPageviews, i, 0)
        }
      });
      interval1 = setInterval(function () {
        batch1.execute(function (responseMap) {
          for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
            let dataActiveusers = responseMap[`${i}-1`],
                dataPageviews   = responseMap[`${i}-2`];
            updateAnalytics(dataActiveusers, dataPageviews, i, 0)
          }
        });
      }, 30000)
      setTimeout(function (){
        batch2.execute(function (responseMap) {
          for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
            let dataActiveusers = responseMap[`${i}-1`],
                dataPageviews   = responseMap[`${i}-2`];
            updateAnalytics(dataActiveusers, dataPageviews, i, 5)
          }
        });
        interval2 = setInterval(function () {
          batch2.execute(function (responseMap) {
            for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
              let dataActiveusers = responseMap[`${i}-1`],
                  dataPageviews   = responseMap[`${i}-2`];
              updateAnalytics(dataActiveusers, dataPageviews, i, 5)
            }
          });
        }, 30000)
      }, 3000);
      setTimeout(function (){
        batch3.execute(function (responseMap) {
          for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
            let dataActiveusers = responseMap[`${i}-1`],
                dataPageviews   = responseMap[`${i}-2`];
            updateAnalytics(dataActiveusers, dataPageviews, i, 10)
          }
        });
        interval3 = setInterval(function () {
          batch3.execute(function (responseMap) {
            for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
              let dataActiveusers = responseMap[`${i}-1`],
                  dataPageviews   = responseMap[`${i}-2`];
              updateAnalytics(dataActiveusers, dataPageviews, i, 10)
            }
          });
        }, 30000)
      }, 6000);
      setTimeout(function (){
        batch4.execute(function (responseMap) {
          for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
            let dataActiveusers = responseMap[`${i}-1`],
                dataPageviews   = responseMap[`${i}-2`];
            updateAnalytics(dataActiveusers, dataPageviews, i, 15)
          }
        });
        interval4 = setInterval(function () {
          batch4.execute(function (responseMap) {
            for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
              let dataActiveusers = responseMap[`${i}-1`],
                  dataPageviews   = responseMap[`${i}-2`];
              updateAnalytics(dataActiveusers, dataPageviews, i, 15)
            }
          });
        }, 30000)
      }, 9000);
      setTimeout(function (){
        batch5.execute(function (responseMap) {
          for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
            let dataActiveusers = responseMap[`${i}-1`],
                dataPageviews   = responseMap[`${i}-2`];
            updateAnalytics(dataActiveusers, dataPageviews, i, 20)
          }
        });
        interval5 = setInterval(function () {
          batch5.execute(function (responseMap) {
            for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
              let dataActiveusers = responseMap[`${i}-1`],
                  dataPageviews   = responseMap[`${i}-2`];
              updateAnalytics(dataActiveusers, dataPageviews, i, 20)
            }
          });
        }, 30000)
      }, 12000);
      setTimeout(function (){
        batch6.execute(function (responseMap) {
          for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
            let dataActiveusers = responseMap[`${i}-1`],
                dataPageviews   = responseMap[`${i}-2`];
            updateAnalytics(dataActiveusers, dataPageviews, i, 25)
          }
        });
        interval6 = setInterval(function () {
          batch6.execute(function (responseMap) {
            for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
              let dataActiveusers = responseMap[`${i}-1`],
                  dataPageviews   = responseMap[`${i}-2`];
              updateAnalytics(dataActiveusers, dataPageviews, i, 25)
            }
          });
        }, 30000)
      }, 15000);
      is_requesting = true

      document.addEventListener("visibilitychange", function () {
        switch(document.visibilityState) {
          case "hidden":
            clearInterval(interval1);
            clearInterval(interval2);
            clearInterval(interval3);
            clearInterval(interval4);
            clearInterval(interval5);
            clearInterval(interval6);
            is_requesting = false;
            console.log("stop request")
            break;
          case "visible":
            if (!is_requesting) {
              console.log("request sau khi bat lai")
              batch1.execute(function (responseMap) {
                for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                  let dataActiveusers = responseMap[`${i}-1`],
                      dataPageviews   = responseMap[`${i}-2`];
                  updateAnalytics(dataActiveusers, dataPageviews, i, 0)
                }
              });
              interval1 = setInterval(function () {
                batch1.execute(function (responseMap) {
                  for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                    let dataActiveusers = responseMap[`${i}-1`],
                        dataPageviews   = responseMap[`${i}-2`];
                    updateAnalytics(dataActiveusers, dataPageviews, i, 0)
                  }
                });
              }, 30000)
              setTimeout(function (){
                batch2.execute(function (responseMap) {
                  for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                    let dataActiveusers = responseMap[`${i}-1`],
                        dataPageviews   = responseMap[`${i}-2`];
                    updateAnalytics(dataActiveusers, dataPageviews, i, 5)
                  }
                });
                interval2 = setInterval(function () {
                  batch2.execute(function (responseMap) {
                    for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                      let dataActiveusers = responseMap[`${i}-1`],
                          dataPageviews   = responseMap[`${i}-2`];
                      updateAnalytics(dataActiveusers, dataPageviews, i, 5)
                    }
                  });
                }, 30000)
              }, 3000);
              setTimeout(function (){
                batch3.execute(function (responseMap) {
                  for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                    let dataActiveusers = responseMap[`${i}-1`],
                        dataPageviews   = responseMap[`${i}-2`];
                    updateAnalytics(dataActiveusers, dataPageviews, i, 10)
                  }
                });
                interval3 = setInterval(function () {
                  batch3.execute(function (responseMap) {
                    for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                      let dataActiveusers = responseMap[`${i}-1`],
                          dataPageviews   = responseMap[`${i}-2`];
                      updateAnalytics(dataActiveusers, dataPageviews, i, 10)
                    }
                  });
                }, 30000)
              }, 6000);
              setTimeout(function (){
                batch4.execute(function (responseMap) {
                  for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                    let dataActiveusers = responseMap[`${i}-1`],
                        dataPageviews   = responseMap[`${i}-2`];
                    updateAnalytics(dataActiveusers, dataPageviews, i, 15)
                  }
                });
                interval4 = setInterval(function () {
                  batch4.execute(function (responseMap) {
                    for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                      let dataActiveusers = responseMap[`${i}-1`],
                          dataPageviews   = responseMap[`${i}-2`];
                      updateAnalytics(dataActiveusers, dataPageviews, i, 15)
                    }
                  });
                }, 30000)
              }, 9000);
              setTimeout(function (){
                batch5.execute(function (responseMap) {
                  for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                    let dataActiveusers = responseMap[`${i}-1`],
                        dataPageviews   = responseMap[`${i}-2`];
                    updateAnalytics(dataActiveusers, dataPageviews, i, 20)
                  }
                });
                interval5 = setInterval(function () {
                  batch5.execute(function (responseMap) {
                    for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                      let dataActiveusers = responseMap[`${i}-1`],
                          dataPageviews   = responseMap[`${i}-2`];
                      updateAnalytics(dataActiveusers, dataPageviews, i, 20)
                    }
                  });
                }, 30000)
              }, 12000);
              setTimeout(function (){
                batch6.execute(function (responseMap) {
                  for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                    let dataActiveusers = responseMap[`${i}-1`],
                        dataPageviews   = responseMap[`${i}-2`];
                    updateAnalytics(dataActiveusers, dataPageviews, i, 25)
                  }
                });
                interval6 = setInterval(function () {
                  batch6.execute(function (responseMap) {
                    for(var i = 0; i < parseInt(Object.keys(responseMap).length/2); i++) {
                      let dataActiveusers = responseMap[`${i}-1`],
                          dataPageviews   = responseMap[`${i}-2`];
                      updateAnalytics(dataActiveusers, dataPageviews, i, 25)
                    }
                  });
                }, 30000)
              }, 15000);
              is_requesting = true;
            }
            break;
        }
      });

      function updateAnalytics(dataActiveusers, dataPageviews, passIndex, startNumber) {
        try {
          let index   = passIndex + startNumber,
              result  = dataActiveusers.result,
              $el     = $(`#js-analytics-${result.profileInfo.profileId}`),
              total   = parseInt(result.totalsForAllResults["rt:activeUsers"]),
              $country = $el.find('.js-country');
          animateValue($el.find(".js-chart-total")[0], parseInt($el.find(".js-chart-total")[0].innerHTML) || 0, total, 2000);

          // set chart
          charts[index].data.datasets[0].data = dataPageviews.result.rows.map(m => m[1]).reverse();
          charts[index].update();

          // set pupolar path
          let pupolarPath = {};
          result.rows.forEach(el => {
            if (!Object.keys(pupolarPath).includes(el[0])) {
              pupolarPath[el[0]] = parseInt(el[3]);
            } else {
              pupolarPath[el[0]] += parseInt(el[3]);
            }
          })
          let mostPaths = Object.keys(pupolarPath).sort(function(a,b){return pupolarPath[b]-pupolarPath[a]});
          $el.find(".js-path-name").each(function (ind, pathname) {
            let $pathname = $(pathname);
            if(result.rows[ind][2] == 'Social Instant Article') {
              $pathname.find('span').text(`IA: ${mostPaths[ind].substring(1)}`);
            } else {
              $pathname.find('span').text(mostPaths[ind].substring(1));
            }
            $el.find('.js-path-views')[ind].innerHTML = pupolarPath[mostPaths[ind]];
          });
          // set country
          let country = {};
          result.rows.forEach(el => {
            if (!Object.keys(country).includes(el[1])) {
              country[el[1]] = parseInt(el[3]);
            } else {
              country[el[1]] += parseInt(el[3]);
            }
          })
          $country.empty();
          Object.keys(country).sort(function(a,b){return country[b]-country[a]}).forEach((el, countryIndex) => {
            if (countryIndex >=5) return;
            let nameCountry;
            if(el.includes(' ')) {
              nameCountry = el.match(/\b(\w)/g).join('')
            } else { nameCountry = el }
            $country.append(`<div class="d-inline-flex small mr-1"><i class="fas fa-circle text-success mr-1" style="line-height:inherit;"></i><div class="mr-1">${nameCountry}</div><div>${country[el]}</div></div>`)
          })
        } catch (e) {
          console.log(e)
        }
      }
    });

    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    } else {
      new Sortable(document.getElementById('sortable'), {
        animation: 150,
        ghostClass: 'blue-background-class'
      });
    }
  }
  /*===================================*
  02. Dashboard
  /*===================================*/
  if ($('#js-pageviews').length > 0) {
    /*===================================*
    Page Views Chart
    /*===================================*/
    $(".js-get-pageviews").on("click", function () {
      // body...
      Swal.showLoading();
      $.get('/home/pageviews', function(response) {
          let date,
              new_date,
              $pageViewsChart = $('#js-pageviews-chart'),
              dataChart       = response["data_chart"],
              labels          = [];
          for (let step = 1; step <= dataChart.length; step++) {
            date = new Date()
            date.setDate(date.getDate() - step);
            labels.unshift(`${date.getDate()} Thg ${date.getMonth() + 1}`)
          }
          let data  = {
                        labels: labels,
                        datasets: [{
                          data: dataChart.map(x=>+x),
                          label: "Số lượt xem",
                          fill: {
                            target: 'origin',
                            above: '#e5f3fa',
                          },
                          borderColor: '#048dc7',
                          tension: 0.1,
                          pointRadius: 5,
                        }]
                      },
              config = {
                          type: 'line',
                          data: data,
                          options: {
                            scales: {
                              x: {
                                grid: {
                                  display: false
                                }
                              },
                              y: {
                                grid: {
                                  display: false
                                }
                              }
                            }
                          }
                       };
          new Chart($pageViewsChart, config);
          $('.js-total-pageviews').text(response["total_pageviews"].toLocaleString());
          Swal.close();
      });
      $(this).attr("disabled", true)
    })

  }

  /*===================================*
  X. Standard
  /*===================================*/
  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

})
